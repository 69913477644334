
import {defineComponent, onMounted, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ChartCode, ChartType} from "@/core/config/Constant";

export default defineComponent({
  name: "CaseManagement",
  components: {},
  setup() {
    const clientId = ref('');
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true;
      setCurrentPageBreadcrumbsWithParams("Case Management Dashboard", [])
    })

    return{
      mounted,
      clientId,
      ChartCode,
      ChartType,
    }
  }
})
